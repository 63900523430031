import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';


import ERImage from "../images/er.jpeg";
import CodeImage from "../images/code.jpeg";
import CovidImage from "../images/covid.jpeg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: 200,
        backgroundColor: theme.palette.secondary.dark
    },
      title: {
        flexGrow: 1,
        color: theme.palette.secondary.light,
        fontStyle: 'bold',
        fontSize: 50,
        fontWeight: 400,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5)
      },
      rights: {
          color: theme.palette.secondary.light,
          fontSize: 14,
          fontWeight: 100,
      }
}))


export default function FooterSection(props) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
        <Typography className={classes.title}>
            > LITTHUB
        </Typography>
        <Typography className={classes.rights}>© LITTHUB 2021. All rights reserved.</Typography>
        </div>
    )
}